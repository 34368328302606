import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

import styled from 'styled-components'

const MainWrapper = styled.div`
margin: 0;
`

const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "What Are These Articles Used For?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "These articles are often used for promotion and marketing, posted on blogs and sometimes used with printed material."
        }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "Why Do We Need Writers?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Codestaff writers turn the ordinary, everyday moments into art. If you want to embed narratives into your products and get more engagement and retention, you need a Codestaff writer."
        }
    }
}

const Writer = () => (
    <MainWrapper>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
        </Helmet>
        <main>
            <section className="cx_catagories">
                <div className="container-fluid cx_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-10">
                                <h2>
                                    Hire top freelance<br /><span> Writers.</span>
                                </h2>
                                <p>
                                    Codestaff is a marketplace for top Writers. Top
                                    companies and start-ups choose Codestaff professional Writers
                                    for their mission critical software projects.
                            </p>
                                <Link to="/hire/" className="skills-banner-btn">
                                    Hire a top Writer now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <CxSearch title='HIRE FREELANCE WRITERS' banner='/writer.png' bannerAlt='writer banner' />
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>)

export default Writer