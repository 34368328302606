import React from 'react'
import Layout from '../components/layout'
import Writer from '../components/categories/writer'
import Seo from '../components/seo'

const WriterPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Writer />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Writer Freelancers | Codestaff'
  const description =
    'Hire the best Writer freelancers at Codestaff. Get the top 1% of Writer professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default WriterPage
